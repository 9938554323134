<template>
  <div>
    <el-card>
      <common-user-center-title :title="'申请成为推广员'"></common-user-center-title>
      <el-alert
        title="提示"
        type="warning"
        :closable="false"
        description="请完善下面信息，申请成为推广员吧！分销提成，足不出户，获取可观收入！"
        show-icon>
      </el-alert>
      <el-form class="mt-2" :model="applyDistributionForm" :rules="applyDistributionFormRules"
               ref="applyDistributionFormRef" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="applyDistributionForm.realName"></el-input>
            </el-form-item>
            <el-form-item label="小店名称" prop="storeName">
              <el-input v-model="applyDistributionForm.storeName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="applyDistributionSubmit">立即申请</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import {applyDistributionUrl} from '@/api/index'
export default {
  name: 'ApplyDistribution',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      applyDistributionForm:{
        // 身份证号，因为考虑到个人隐私问题，所以先隐藏不填
        idCard:'',
        realName:'',
        storeName:''
      },
      applyDistributionFormRules:{
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        storeName: [
          { required: true, message: '请输入小店名称', trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    // 提交申请成为分销商
    applyDistributionSubmit(){
      this.$refs.applyDistributionFormRef.validate(async valid => {
        if (!valid) return
        const {data:res} = await this.$http.post(applyDistributionUrl,this.applyDistributionForm)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.$message.success('已提交申请，请耐心等待管理员进行审核')
      })
    }
  }
}
</script>

<style scoped>

</style>
